
import Vue from 'vue'
import SaIcon from '~/components/_general/SaIcon.vue'

export default Vue.extend({
  name: 'PlayerCenteredButton',
  components: { SaIcon },
  props: {
    hidden: {
      type: Boolean,
    },
    clickable: {
      type: Boolean,
    },
    coloredControls: {
      type: Boolean,
    },
    coloredControlsColor: {
      type: String,
      default: '',
    },
    saturation: {
      type: Number,
      default: 100,
      validator: (value: number) => {
        return value >= 0 && value <= 100
      },
    },
  },
})
