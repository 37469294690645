
import Vue, { PropType } from 'vue'
import {
  CastContext,
  CastOptions,
  CastSession,
  CastStateEventData,
  Chrome,
  ChromecastFramework,
  MediaInfo,
  RemotePlayer,
  RemotePlayerController,
  SessionStateEventData,
} from '~/models/chromecast'
import { Sermon } from '~/models/sermon'
import { PlayerCastPlatform } from '~/assets/ts/enums'
import SaIcon from '~/components/_general/SaIcon.vue'

// AirPlay
// https://developer.apple.com/design/human-interface-guidelines/airplay/overview/media-playback/
// https://developer.apple.com/documentation/webkitjs/adding_an_airplay_button_to_your_safari_media_controls

declare global {
  interface Window {
    cast: ChromecastFramework
    chrome: Chrome
    WebKitPlaybackTargetAvailabilityEvent: string
    __onGCastApiAvailable: (isAvailable: boolean) => void
  }
  interface HTMLAudioElement {
    webkitShowPlaybackTargetPicker: () => void
    webkitCurrentPlaybackTargetIsWireless: boolean | undefined
  }
  interface HTMLVideoElement {
    webkitShowPlaybackTargetPicker: () => void
    webkitCurrentPlaybackTargetIsWireless: boolean | undefined
  }
}

export default Vue.extend({
  name: 'PlayerCastButton',
  components: { SaIcon },
  props: {
    audio: {
      type: Boolean,
    },
    audioElement: {
      type: process.server ? Object : HTMLAudioElement,
      default: undefined,
    },
    videoElement: {
      type: process.server ? Object : HTMLVideoElement,
      default: undefined,
    },
    sermon: {
      type: Object as PropType<Sermon>,
      default: undefined,
    },
  },
  data() {
    return {
      airplay: false,
      chromecast: false,
      audioSetup: false,
      videoSetup: false,
      chromecastMutedLocal: false,
      chromecastVolumeLocal: 100,
      chromecastPausedLocal: false,
      chromecastCastSession: null as CastSession | null,
      chromecastInstance: undefined as CastContext | undefined,
      chromecastPlayer: undefined as RemotePlayer | undefined,
      chromecastPlayerController: undefined as
        | RemotePlayerController
        | undefined,
      delayChromecastTimeUpdate: false,
    }
  },
  head() {
    return {
      script: [
        {
          src: '//www.gstatic.com/cv/js/sender/v1/cast_sender.js?loadCastFramework=1',
        },
      ],
    }
  },
  computed: {
    castStatus(): PlayerCastPlatform {
      return this.$store.getters['player/casting']
    },
    casting(): boolean {
      return this.castStatus !== PlayerCastPlatform.None
    },
    chromecastPaused(): boolean {
      return this.$store.getters['player/chromecastPaused']
    },
    media(): HTMLAudioElement | HTMLVideoElement | undefined {
      return this.audio
        ? (this.audioElement as HTMLAudioElement)
        : (this.videoElement as HTMLVideoElement)
    },
    volume(): number {
      return this.$store.getters['player/volume']
    },
    muted(): boolean {
      return this.$store.getters['player/muted']
    },
  },
  watch: {
    audioElement() {
      this.setupCasting()
    },
    videoElement() {
      this.setupCasting()
    },
    chromecastPaused() {
      if (this.chromecastPaused !== this.chromecastPausedLocal) {
        this.chromecastPlayerController?.playOrPause()
        this.setChromecastPaused(this.chromecastPaused)
      }
    },
    volume() {
      if (this.volume !== this.chromecastVolumeLocal) {
        this.updateChromecastVolume()
      }
    },
    muted() {
      if (this.muted !== this.chromecastMutedLocal) {
        this.updateChromecastMuted()
      }
    },
  },
  mounted() {
    this.resetLocalParams()
    this.setupCasting()
  },
  destroyed() {
    this.resetLocalParams()
    this.destroyChromecast()
  },
  methods: {
    resetLocalParams() {
      this.setCastStatus(PlayerCastPlatform.None)
      this.setChromecastPaused(false)
    },
    setupCasting() {
      this.setupAirplay()
      this.setupComcast()
    },
    setupComcast() {
      if (!this.$isClient) return
      if (!document.getElementsByTagName('google-cast-launcher').length) {
        const c = this.$refs.chromecast as HTMLElement
        c.appendChild(document.createElement('google-cast-launcher'))
      }
      this.chromecast = !!window.cast
      if (this.chromecast) {
        this.initializeChromecast()
      } else {
        window.__onGCastApiAvailable = (isAvailable: boolean) => {
          this.chromecast = isAvailable
          this.initializeChromecast()
        }
      }
    },
    initializeChromecast() {
      if (!window.cast || !this.chromecast || !this.media) return
      this.chromecastInstance = window.cast.framework.CastContext.getInstance()
      this.chromecastPlayer = new window.cast.framework.RemotePlayer()
      this.chromecastPlayerController =
        new window.cast.framework.RemotePlayerController(this.chromecastPlayer)
      this.chromecastInstance.addEventListener(
        window.cast.framework.CastContextEventType.CAST_STATE_CHANGED,
        (event: CastStateEventData) => {
          this.chromecast =
            event.castState !==
            window.cast.framework.CastState.NO_DEVICES_AVAILABLE
        }
      )
      // This may only happen when developing locally, but it's a good backup anyway
      if (this.chromecastInstance.getCurrentSession()) {
        this.resumeChromecast()
      }
      this.chromecastInstance.addEventListener(
        window.cast.framework.CastContextEventType.SESSION_STATE_CHANGED,
        (event: SessionStateEventData) => {
          if (!this.chromecastInstance) return
          this.chromecastCastSession =
            this.chromecastInstance.getCurrentSession()
          switch (event.sessionState) {
            case window.cast.framework.SessionState.SESSION_STARTED:
              this.startChromecast()
              break
            case window.cast.framework.SessionState.SESSION_RESUMED:
              this.resumeChromecast()
              break
            case window.cast.framework.SessionState.SESSION_ENDED:
              this.endChromecast()
              break
          }
        }
      )
      const castOptions = {
        receiverApplicationId: '3524DCDC',
        autoJoinPolicy: window.chrome.cast.AutoJoinPolicy.ORIGIN_SCOPED,
      } as CastOptions
      this.chromecastInstance.setOptions(castOptions)
    },
    getChromecastMetadata(): MediaInfo | undefined {
      if (!this.sermon) return undefined
      const type = this.audio ? 'audio' : 'video'
      const url = this.sermon.media[type][0].streamURL as string
      const mediaInfo = new window.chrome.cast.media.MediaInfo(url, type)

      // https://developers.google.com/cast/docs/styled_receiver
      mediaInfo.metadata = new window.chrome.cast.media.GenericMediaMetadata()
      mediaInfo.metadata.images = [
        new window.chrome.cast.Image(this.sermon.chromecastArt(this.audio)),
      ]
      mediaInfo.metadata.subtitle = this.sermon.metadata
      mediaInfo.metadata.title = this.sermon.fullTitle
      return mediaInfo
    },
    startChromecast() {
      if (!this.sermon || !this.media) return
      const loadRequest = new window.chrome.cast.media.LoadRequest(
        this.getChromecastMetadata() as MediaInfo
      )
      loadRequest.currentTime = this.media.currentTime
      loadRequest.playbackRate = this.media.playbackRate
      this.chromecastCastSession?.loadMedia(loadRequest).then(
        () => {
          this.bindChromecastEvents()
        },
        (errorCode) => {
          console.warn(`Chromecast load failure: ${errorCode}`)
          this.setCastStatus(PlayerCastPlatform.None)
        }
      )
    },
    resumeChromecast() {
      if (this.resumedSameSermon()) {
        this.bindChromecastEvents()
      } else {
        this.startChromecast()
      }
    },
    resumedSameSermon() {
      // checks if it is the same sermon via the metadata
      if (!this.chromecastPlayer || !this.chromecastPlayer.mediaInfo) {
        return false
      }
      const sessionMetadata = this.chromecastPlayer.mediaInfo.metadata
      const sermonMetadata = this.getChromecastMetadata()?.metadata
      if (!sermonMetadata) {
        return false
      }
      const matchingTitle = sermonMetadata.title === sessionMetadata.title
      const matchingSubTitle =
        sermonMetadata.subtitle === sessionMetadata.subtitle
      if (matchingSubTitle && matchingTitle) {
        return true
      }
    },
    endChromecast() {
      this.chromecastCastSession?.endSession(true)
      this.setCastStatus(PlayerCastPlatform.None)
    },
    setCastStatus(status: PlayerCastPlatform) {
      this.$store.commit('player/SET_PLAYER_CASTING', status)
    },
    updateChromecastVolume() {
      if (!this.chromecastPlayerController || !this.chromecastPlayer) return
      this.chromecastPlayer.volumeLevel =
        this.$store.getters['player/volume'] / 100
      this.chromecastPlayerController.setVolumeLevel()
    },
    updateChromecastMuted() {
      if (!this.chromecastPlayerController || !this.chromecastPlayer) return
      if (
        this.chromecastPlayer.isMuted !== this.$store.getters['player/muted']
      ) {
        this.chromecastPlayerController.muteOrUnmute()
      }
    },
    updateChromecastCurrentTime() {
      if (this.delayChromecastTimeUpdate || !this.media) return
      if (!this.chromecastPlayerController || !this.chromecastPlayer) return
      if (
        Math.floor(this.chromecastPlayer.currentTime) !==
        Math.floor(this.media.currentTime)
      ) {
        this.delayChromecastTimeUpdate = true
        this.chromecastPlayer.currentTime = this.media.currentTime
        this.chromecastPlayerController.seek()
        this.$nextTick(() => {
          this.delayChromecastTimeUpdate = false
        })
      }
    },
    setChromecastPaused(paused: boolean) {
      this.$store.commit('player/SET_CHROMECAST_PAUSED', paused)
      this.chromecastPausedLocal = paused
    },
    bindChromecastEvents() {
      if (
        !this.chromecastPlayerController ||
        !this.chromecastPlayer ||
        !this.media
      )
        return

      const playerLoaded = this.media.readyState >= 4
      if (!playerLoaded) {
        this.media.load()
      }

      this.updateChromecastVolume()
      this.updateChromecastMuted()
      this.setChromecastPaused(this.chromecastPlayer.isPaused)
      if (!this.media.currentTime && this.chromecastPlayer.currentTime) {
        this.media.currentTime = this.chromecastPlayer.currentTime
      }

      this.media.addEventListener(
        'timeupdate',
        this.updateChromecastCurrentTime
      )

      this.chromecastPlayerController.addEventListener(
        window.cast.framework.RemotePlayerEventType.CURRENT_TIME_CHANGED,
        (event) => {
          if (!this.media) return
          this.media.currentTime = event.value
        }
      )
      this.chromecastPlayerController.addEventListener(
        window.cast.framework.RemotePlayerEventType.VOLUME_LEVEL_CHANGED,
        (event) => {
          this.chromecastVolumeLocal = event.value * 100
          this.$store.commit('player/SET_PLAYER_VOLUME', event.value * 100)
        }
      )
      this.chromecastPlayerController.addEventListener(
        window.cast.framework.RemotePlayerEventType.IS_MUTED_CHANGED,
        (event) => {
          this.chromecastMutedLocal = event.value
          this.$store.commit('player/SET_PLAYER_MUTED', event.value)
        }
      )
      this.chromecastPlayerController.addEventListener(
        window.cast.framework.RemotePlayerEventType.IS_PAUSED_CHANGED,
        (event) => {
          this.setChromecastPaused(event.value)
        }
      )
      this.chromecastPlayerController.addEventListener(
        window.cast.framework.RemotePlayerEventType.DISPLAY_STATUS_CHANGED,
        (event) => {
          if (event.value !== '') return
          this.endChromecast()
        }
      )
      this.setCastStatus(PlayerCastPlatform.Chromecast)
    },
    destroyChromecast() {
      if (this.chromecastInstance) {
        this.chromecastInstance.removeEventListener(
          window.cast.framework.CastContextEventType.SESSION_STATE_CHANGED
        )
        this.chromecastInstance.removeEventListener(
          window.cast.framework.CastContextEventType.CAST_STATE_CHANGED
        )
      }
      if (this.chromecastPlayerController) {
        this.chromecastPlayerController.removeEventListener(
          window.cast.framework.RemotePlayerEventType.CURRENT_TIME_CHANGED
        )
        this.chromecastPlayerController.removeEventListener(
          window.cast.framework.RemotePlayerEventType.VOLUME_LEVEL_CHANGED
        )
        this.chromecastPlayerController.removeEventListener(
          window.cast.framework.RemotePlayerEventType.IS_MUTED_CHANGED
        )
        this.chromecastPlayerController.removeEventListener(
          window.cast.framework.RemotePlayerEventType.IS_PAUSED_CHANGED
        )
        this.chromecastPlayerController.removeEventListener(
          window.cast.framework.RemotePlayerEventType.DISPLAY_STATUS_CHANGED
        )
      }
      if (this.media) {
        this.media.removeEventListener(
          'timeupdate',
          this.updateChromecastCurrentTime
        )
      }
    },
    setupAirplay() {
      const canAirplay = !!window.WebKitPlaybackTargetAvailabilityEvent
      if (!canAirplay) return

      const media = [] as HTMLElement[]
      if (this.audioElement && !this.audioSetup) {
        media.push(this.audioElement)
        this.audioSetup = true
      }
      if (this.videoElement && !this.videoSetup) {
        media.push(this.videoElement)
        this.videoSetup = true
      }

      if (media.length) {
        const airplayTargetEvent = 'webkitplaybacktargetavailabilitychanged'
        const airplayChangedEvent =
          'webkitcurrentplaybacktargetiswirelesschanged'
        media.forEach((element) => {
          element.addEventListener(airplayTargetEvent, (event: any) => {
            this.airplay = event.availability === 'available'
          })
          element.addEventListener(airplayChangedEvent, () => {
            this.setCastStatus(
              this.casting
                ? PlayerCastPlatform.None
                : PlayerCastPlatform.Airplay
            )
          })
        })
      }
    },
    click() {
      if (!this.airplay) return
      const element = this.media
      if (!element) return
      const loaded = element.readyState >= 4
      if (loaded) {
        element.webkitShowPlaybackTargetPicker()
      } else {
        element.addEventListener('loadedmetadata', () => {
          element.webkitShowPlaybackTargetPicker()
        })
        element.load()
      }
    },
  },
})
