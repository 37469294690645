
import Vue from 'vue'
import SaIcon from '~/components/_general/SaIcon.vue'

export default Vue.extend({
  name: 'PlayerWebcastAudioContainer',
  components: { SaIcon },
  props: {
    prop: {
      type: Boolean,
      default: false,
    },
  },
})
