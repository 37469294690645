
import Vue, { PropType } from 'vue'
import { TranslateResult } from 'vue-i18n'
import {
  PlayerCastPlatform,
  PlayerMediaType,
  PlayerStatus,
} from 'assets/ts/enums'
import { Sermon } from '@/models/sermon'
import KeydownEvent from '~/models/generic/KeydownEvent'
import { siteSermonUrl, siteWebcastUrl } from '~/assets/ts/utils/urls'
import { clamp } from '~/assets/ts/utils/math'
import { Webcast } from '~/models/webcast'
import { waitOneFrame } from '~/assets/ts/utils/misc'
import PlayerMuteIcon from '~/components/player/MuteIcon.vue'
import SaIcon from '~/components/_general/SaIcon.vue'
import Spinner from '~/components/_general/Spinner.vue'
import InlineIcon from '~/components/_general/InlineIcon.vue'
import PlayerCastButton from '~/components/player/CastButton.vue'
import KeydownObserver from '~/components/_general/KeydownObserver.vue'
import SaSvg from '~/components/_general/SaSvg.vue'

const minPlayerSpeed = 0.5
const maxPlayerSpeed = 2.0
const playerSpeedStep = 0.25
const skipSeconds = 15
const volumeStep = 5

export default Vue.extend({
  name: 'PlayerControls',
  components: {
    SaSvg,
    KeydownObserver,
    PlayerCastButton,
    InlineIcon,
    Spinner,
    SaIcon,
    PlayerMuteIcon,
  },
  props: {
    sermon: {
      type: Object as PropType<Sermon>,
      default: undefined,
    },
    webcast: {
      type: Object as PropType<Webcast>,
      default: undefined,
    },
    customAudio: {
      type: Boolean,
    },
    liveOnly: {
      type: Boolean,
    },
    audioElement: {
      type: process.server ? Object : HTMLAudioElement,
      default: undefined,
    },
    videoElement: {
      type: process.server ? Object : HTMLVideoElement,
      default: undefined,
    },
    video: {
      type: Boolean,
      required: true,
    },
    allowSettingsButton: {
      type: Boolean,
      default: true,
    },
    allowSpeedControls: {
      type: Boolean,
      default: true,
    },
    allowCaptions: {
      type: Boolean,
    },
    allowTranscript: {
      type: Boolean,
    },
    captions: {
      type: Boolean,
    },
    transcript: {
      type: Boolean,
    },
    allowRepeat: {
      type: Boolean,
    },
    repeatPlays: {
      type: Number,
      default: 1,
    },
    repeatTotal: {
      type: Number,
      default: 1,
    },
    fullscreen: {
      type: Boolean,
    },
    settingsOpen: {
      type: Boolean,
    },
    popout: {
      type: Boolean,
    },
    muted: {
      type: Boolean,
    },
    largeAudio: {
      type: Boolean,
    },
    status: {
      type: Number as PropType<PlayerStatus>,
      default: PlayerStatus.Paused,
    },
    useShortcuts: {
      type: Boolean,
      default: true,
    },
    accentColor: {
      type: String,
      default: '',
    },
    invertedTextColor: {
      type: String,
      default: '',
    },
    invertedBgColor: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      tooltipText: '' as TranslateResult,
      showTooltip: false,
      tooltipOffset: 0,
      tooltipKeys: [] as string[],
      hasPlayed: false,
    }
  },
  computed: {
    castStatus(): PlayerCastPlatform {
      return this.$store.getters['player/casting']
    },
    casting(): boolean {
      return this.castStatus !== PlayerCastPlatform.None
    },
    airplay(): boolean {
      return this.castStatus === PlayerCastPlatform.Airplay
    },
    chromecast(): boolean {
      return this.castStatus === PlayerCastPlatform.Chromecast
    },
    chromecastPaused(): boolean {
      return this.$store.getters['player/chromecastPaused']
    },
    mobile(): boolean {
      return this.$device.isMobileOrTablet
    },
    hasVideo(): boolean {
      return this.videoElement !== undefined
    },
    showFancyPlayButton(): boolean {
      if (this.customAudio || this.video || this.casting) return false
      if (this.hasPlayed && this.status <= PlayerStatus.Buffering) return false
      return this.status < PlayerStatus.Playing
    },
    playing(): boolean {
      return this.status === PlayerStatus.Playing || this.chromecastPlaying
    },
    chromecastPlaying(): boolean {
      return this.chromecast && !this.chromecastPaused
    },
    paused(): boolean {
      return !this.playing && !this.ended && !this.loading
    },
    loading(): boolean {
      return (
        this.status === PlayerStatus.Buffering ||
        this.status === PlayerStatus.Loading
      )
    },
    ended(): boolean {
      return this.status === PlayerStatus.Ended
    },
    displayVolume(): number {
      return this.muted ? 0 : this.volume
    },
    volume(): number {
      return this.$store.getters['player/volume']
    },
    speed(): number {
      return this.$store.getters['player/speed']
    },
    speedInt(): number {
      // speed-1: 0.50x
      // speed-2: 0.75x
      // speed-3: 1.00x
      // speed-4: 1.25x
      // speed-5: 1.50x
      // speed-6: 1.75x
      // speed-7: 2.00x
      return (this.speed - minPlayerSpeed) / playerSpeedStep + 1
    },
    displayMuted(): boolean {
      return this.muted || this.volume <= 0
    },
  },
  watch: {
    video() {
      this.clearTooltip()
    },
    status() {
      this.hasPlayed = this.hasPlayed || this.status > PlayerStatus.Loaded
    },
  },
  methods: {
    async toggleSettings() {
      if (!this.settingsOpen) {
        await waitOneFrame()
      }
      this.$emit('showSettings', !this.settingsOpen)
    },
    setTooltip(event: MouseEvent, text: TranslateResult, keys: string[] = []) {
      if (this.mobile) return
      this.tooltipText = text
      this.tooltipKeys = keys
      this.showTooltip = true

      this.$nextTick(() => {
        const container = this.$el as HTMLElement
        const target = event.target as HTMLElement
        const tooltip = this.$refs.tooltip as HTMLElement
        const containerLeft = container.getBoundingClientRect().left
        const targetLeft = target.getBoundingClientRect().left
        const leftPos = targetLeft + target.clientWidth / 2 - containerLeft
        const tooltipWidth = tooltip.clientWidth / 2
        this.tooltipOffset = Math.max(leftPos - tooltipWidth, 0)
      })
    },
    clearTooltip() {
      this.showTooltip = false
    },
    keydown(key: KeydownEvent) {
      if (key.Space || key.K) {
        key.event.preventDefault()
        this.playToggle()
      } else if (key.M) {
        this.toggleMute()
      } else if (key.ArrowUp) {
        key.event.preventDefault()
        this.increaseVolume()
      } else if (key.ArrowDown) {
        key.event.preventDefault()
        this.decreaseVolume()
      } else if (key.ArrowLeft || key.J) {
        this.skip(-1)
      } else if (key.ArrowRight || key.L) {
        this.skip(1)
      } else if (key.GreaterThan) {
        this.setSpeed(this.speed + playerSpeedStep)
      } else if (key.LessThan) {
        this.setSpeed(this.speed - playerSpeedStep)
      } else if (key.Escape) {
        this.back()
      } else if (key.QuestionMark) {
        this.openShortcutList()
      } else if (key.T) {
        this.toggleTranscript()
      } else if (key.C) {
        this.toggleCaptions()
      }
    },
    toggleTranscript() {
      if (!this.allowTranscript) return
      this.$emit('toggleTranscript')
    },
    toggleCaptions() {
      if (!this.allowCaptions) return
      this.$emit('toggleCaptions')
    },
    back() {
      this.$emit('back')
    },
    openShortcutList() {
      this.$emit('openShortcuts')
    },
    playToggle() {
      if (this.chromecast) {
        this.$store.commit(
          'player/SET_CHROMECAST_PAUSED',
          !this.chromecastPaused
        )
      } else {
        this.$emit('playToggle')
      }
    },
    skip(multiplier: number) {
      this.$emit('skip', skipSeconds * multiplier)
    },
    increaseVolume() {
      this.updateVolume(Math.min(this.volume + volumeStep, 100))
    },
    decreaseVolume() {
      this.updateVolume(Math.max(0, this.volume - volumeStep))
    },
    setVolume(event: Event) {
      const target = event.target as HTMLInputElement
      const volume = parseInt(target.value)
      if (volume > 0 && this.muted) {
        this.toggleMute()
      }
      this.updateVolume(volume)
    },
    updateVolume(volume: number) {
      this.$store.commit('player/SET_PLAYER_VOLUME', volume)
    },
    toggleMute() {
      const muted = !this.muted
      this.$emit('mute', muted)
      this.$store.commit('player/SET_PLAYER_MUTED', muted)
    },
    toggleFullscreen() {
      this.$emit('fullscreen')
    },
    setSpeed(speed: number) {
      speed = clamp(speed, minPlayerSpeed, maxPlayerSpeed)
      this.$store.commit('player/SET_PLAYER_SPEED', speed)
    },
    cycleSpeed() {
      const speed =
        this.speed >= maxPlayerSpeed
          ? minPlayerSpeed
          : this.speed + playerSpeedStep
      this.setSpeed(speed)
    },
    openPopup() {
      if (this.sermon) {
        this.sermon.openPopup(
          siteSermonUrl(
            this.sermon,
            this.video ? PlayerMediaType.Video : PlayerMediaType.Audio
          )
        )
      } else if (this.webcast) {
        this.webcast.openPopup(siteWebcastUrl(this.webcast.broadcasterID))
      }
    },
  },
})
