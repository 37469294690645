
import Vue from 'vue'
import { MediaDownload } from '~/models/media'
import { Sermon } from '~/models/sermon'
import CustomDropdown from '~/components/_general/CustomDropdown.vue'
import SaIcon from '~/components/_general/SaIcon.vue'

export default Vue.extend({
  name: 'MediaDownloadDropdown',
  components: { SaIcon, CustomDropdown },
  props: {
    sermon: {
      type: Sermon,
      default: undefined,
    },
    contentClasses: {
      type: String,
      default: '',
    },
    targetClasses: {
      type: String,
      default: '',
    },
    liClasses: {
      type: String,
      default: 'site-colors',
    },
  },
  computed: {
    downloadList(): MediaDownload[] {
      if (!this.sermon || !this.sermon.media) return []
      return this.sermon.media.getDownloadList(this)
    },
  },
  methods: {
    sermonDownloaded(event: Event, close: () => void, download: MediaDownload) {
      close()
      if (!this.sermon) return
      if (!download.url && download.media) {
        event.preventDefault()
        download.media.downloadTranscript(this)
      }
    },
  },
})
