
import Vue, { PropType } from 'vue'
import { SaIconsType } from '~/assets/ts/types/icons'
import SaIcon from '~/components/_general/SaIcon.vue'

export default Vue.extend({
  name: 'PlayerSettingsButton',
  components: { SaIcon },
  props: {
    active: {
      type: Boolean,
    },
    header: {
      type: Boolean,
    },
    openButton: {
      type: Boolean,
    },
    customButton: {
      type: Boolean,
    },
    icon: {
      type: String as PropType<SaIconsType>,
      default: 'check',
    },
  },
})
