var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"transition-opacity duration-300 absolute-fill pointer-events-none z-10",class:[
    _vm.showControls || !_vm.hasPlayed ? 'opacity-100' : 'opacity-0',
    _vm.video
      ? 'h-auto px-3 xxs:px-4 -mt-12 pt-[3.6rem] xxs:pt-16 pb-12'
      : 'pt-2',
  ]},[(_vm.video)?_c('div',{staticClass:"absolute left-0 right-0 top-0 h-40 xxs:h-60 xs:h-70 sm:h-80 bg-gradient-to-b from-gray-900 bg-opacity-30"}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"pointer-events-auto ellipsis relative",class:[
      _vm.embed ? 'mr-6 min-[280px]:mr-12 xs:mr-32 sm:mr-56' : '',
      _vm.video ? 'xxs:text-xl' : !_vm.embed ? 'text-lg' : '',
    ]},[(_vm.sermon)?_c('SaLinkOrHref',{staticClass:"hover:underline",attrs:{"url":_vm.sermonUrl,"is-to":!_vm.embed && !_vm.soloUrls,"target":_vm.embed ? '_blank' : undefined}},[_c('span',{staticClass:"sm:hidden"},[_vm._v(_vm._s(_vm.sermon.displayTitle))]),_vm._v(" "),_c('span',{staticClass:"hidden sm:inline"},[_vm._v(_vm._s(_vm.sermon.fullTitle))])]):(_vm.webcast)?_c('SaLinkOrHref',{staticClass:"hover:underline",attrs:{"url":_vm.webcastUrl,"is-to":!_vm.embed && !_vm.soloUrls,"target":_vm.embed ? '_blank' : undefined}},[_c('span',[_vm._v(_vm._s(_vm.webcastTitle))])]):_vm._e()],1),_vm._v(" "),_c('div',{staticClass:"text-sm pointer-events-auto relative max-xs:!hidden",class:[_vm.video || !_vm.embed ? 'text-sm' : 'text-xs']},[(_vm.sermon)?_c('SermonMetadata',{attrs:{"sermon":_vm.sermon,"display":_vm.metadataDisplay,"show-date":true,"solo-urls":_vm.soloUrls}}):(_vm.webcast)?_c('WebcastMetadata',{attrs:{"display":_vm.webcastDisplay,"webcast":_vm.webcast,"solo-urls":_vm.soloUrls}}):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }