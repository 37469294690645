
import Vue, { PropType } from 'vue'
import { Subtitle, SubtitleTrack } from '~/models/player/subtitle'

export default Vue.extend({
  name: 'PlayerSubtitle',
  props: {
    subtitle: {
      type: Object as PropType<Subtitle>,
      required: true,
    },
    time: {
      type: Number,
      required: true,
    },
    track: {
      type: Object as PropType<SubtitleTrack>,
      default: undefined,
    },
  },
  computed: {
    validTrack(): boolean {
      return this.track?.id === this.subtitle.track.id
    },
    validTime(): boolean {
      return (
        this.time > this.subtitle.startTime && this.time < this.subtitle.endTime
      )
    },
  },
})
