
import Vue from 'vue'
import { TranslateResult } from 'vue-i18n'
import HorizontalRule from '~/components/_general/HorizontalRule.vue'

type PlayerShortcutType =
  | 'play'
  | 'fullscreen'
  | 'mute'
  | 'volumeUp'
  | 'volumeDown'
  | 'skipBackward'
  | 'skipForward'
  | 'speedIncrease'
  | 'speedDecrease'
  | 'back'
  | 'shortcuts'
  | 'cc'
  | 'transcript'
  | 'transcriptSearch'
  | 'transcriptNext'
  | 'transcriptPrevious'
  | 'mediaType'

const PlayerShortcutCategoryArray = [
  'playback',
  'general',
  'video',
  'transcript',
]
type PlayerShortcutCategory = (typeof PlayerShortcutCategoryArray)[number]

export interface PlayerShortcut {
  category: PlayerShortcutCategory
  keys: string[]
  title?: TranslateResult
}

export const PlayerShortcuts = {
  play: {
    category: 'playback',
    keys: ['Space', 'K'],
  },
  fullscreen: {
    category: 'video',
    keys: ['F'],
  },
  mute: {
    category: 'general',
    keys: ['M'],
  },
  volumeUp: {
    category: 'general',
    keys: ['↑'],
  },
  volumeDown: {
    category: 'general',
    keys: ['↓'],
  },
  skipBackward: {
    category: 'playback',
    keys: ['←', 'J'],
  },
  skipForward: {
    category: 'playback',
    keys: ['→', 'L'],
  },
  speedIncrease: {
    category: 'playback',
    keys: ['>'],
  },
  speedDecrease: {
    category: 'playback',
    keys: ['<'],
  },
  back: {
    category: 'general',
    keys: ['Esc'],
  },
  shortcuts: {
    category: 'general',
    keys: ['?'],
  },
  cc: {
    category: 'video',
    keys: ['C'],
  },
  transcript: {
    category: 'transcript',
    keys: ['T'],
  },
  transcriptSearch: {
    category: 'transcript',
    keys: ['/'],
  },
  transcriptNext: {
    category: 'transcript',
    keys: ['Enter'],
  },
  transcriptPrevious: {
    category: 'transcript',
    keys: ['Shift + Enter'],
  },
  mediaType: {
    category: 'video',
    keys: ['A', 'V'],
  },
} as Record<PlayerShortcutType, PlayerShortcut>

export interface PlayerShortcutGroup {
  category: PlayerShortcutCategory
  title: TranslateResult
  shortcuts: PlayerShortcut[]
}

export function GetShortcutCategoryTitle(
  context: Record<string, any>,
  category: PlayerShortcutCategory
): TranslateResult {
  switch (category) {
    case 'video':
      return context.$t('Video')
    case 'transcript':
      return context.$t('Transcript')
    case 'playback':
      return context.$t('Playback')
    case 'general':
    default:
      return context.$t('General')
  }
}

export function GetShortcutTitle(
  context: Record<string, any>,
  shortcut: PlayerShortcutType
): TranslateResult {
  switch (shortcut) {
    case 'play':
      return context.$t('Play')
    case 'fullscreen':
      return context.$t('Fullscreen')
    case 'mute':
      return context.$t('Mute')
    case 'volumeUp':
      return context.$t('Volume Up')
    case 'volumeDown':
      return context.$t('Volume Down')
    case 'skipBackward':
      return context.$t('Skip Backward')
    case 'skipForward':
      return context.$t('Skip Forward')
    case 'speedIncrease':
      return context.$t('Increase Speed')
    case 'speedDecrease':
      return context.$t('Decrease Speed')
    case 'back':
      return context.$t('Close/Back')
    case 'shortcuts':
      return context.$t('View Shortcuts')
    case 'cc':
      return context.$t('Closed Captions')
    case 'transcript':
      return context.$t('Enable')
    case 'transcriptSearch':
      return context.$t('Search')
    case 'transcriptNext':
      return context.$t('Next Search')
    case 'transcriptPrevious':
      return context.$t('Previous Search')
    case 'mediaType':
      return context.$t('Switch Media Type')
  }
}

export default Vue.extend({
  name: 'PlayerShortcutModal',
  components: { HorizontalRule },
  props: {
    video: {
      type: Boolean,
    },
    transcript: {
      type: Boolean,
    },
    dark: {
      type: Boolean,
    },
    useTheme: {
      type: Boolean,
    },
  },
  computed: {
    modalBackground(): string {
      if (this.useTheme) return 'modal-theme text-theme'
      if (this.video) return ''
      return 'overlay-transparent'
    },
    keyBackground(): string {
      if (this.useTheme) return 'bg-current'
      return this.video || this.dark ? 'bg-light' : 'bg-dark'
    },
    keyColor(): string {
      if (this.useTheme) return 'text-white dark:text-black'
      return this.video || this.dark ? 'text-light' : 'text-dark'
    },
    containerBackground(): string {
      if (this.useTheme) return ''
      if (this.video || this.dark) {
        return `${this.video ? 'bg-black' : 'bg-dark'} text-dark`
      }
      return 'bg-light text-light'
    },
    shortcutsArray(): PlayerShortcut[] {
      return Object.keys(PlayerShortcuts).map((s) => {
        const t = s as PlayerShortcutType
        return { ...PlayerShortcuts[t], title: GetShortcutTitle(this, t) }
      })
    },
    groups(): PlayerShortcutGroup[] {
      let array = PlayerShortcutCategoryArray as PlayerShortcutCategory[]
      if (!this.video) {
        array = array.filter((c: PlayerShortcutCategory) => c !== 'video')
      }
      if (!this.transcript) {
        array = array.filter((c: PlayerShortcutCategory) => c !== 'transcript')
      }
      return array.map((c: PlayerShortcutCategory) => {
        return {
          category: c,
          title: GetShortcutCategoryTitle(this, c),
          shortcuts: this.shortcutsArray.filter((s) => s.category === c),
        }
      })
    },
  },
  mounted() {
    this.$modal.show('shortcuts')
  },
})
