
import Vue from 'vue'
import KeydownEvent from '~/models/generic/KeydownEvent'
import KeydownObserver from '~/components/_general/KeydownObserver.vue'

declare global {
  interface Element {
    webkitRequestFullscreen: () => Promise<void>
    mozRequestFullScreen: () => Promise<void>
    msRequestFullscreen: () => Promise<void>
  }

  interface Document {
    webkitFullscreenEnabled: boolean
    webkitFullscreenElement: Element
    webkitExitFullscreen: () => Promise<void>
    mozFullScreenEnabled: boolean
    mozFullScreenElement: Element
    mozCancelFullScreen: () => Promise<void>
    msFullscreenEnabled: boolean
    msFullscreenElement: Element
    msExitFullscreen: () => Promise<void>
  }
}

export default Vue.extend({
  name: 'FullscreenElement',
  components: { KeydownObserver },
  props: {
    toggle: {
      type: Number,
      default: 0,
    },
    type: {
      type: String,
      default: 'div',
    },
    focusOnFullscreen: {
      type: Boolean,
      default: true,
    },
    allowShortcut: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      isFullscreen: false,
    }
  },
  computed: {
    canFullscreen(): boolean {
      if (!this.$isClient) return false
      return (
        document.fullscreenEnabled ||
        document.webkitFullscreenEnabled ||
        document.mozFullScreenEnabled ||
        document.msFullscreenEnabled
      )
    },
  },
  watch: {
    toggle() {
      this.toggleFullscreen()
    },
  },
  mounted() {
    document.addEventListener('fullscreenchange', this.updateIsFullscreen)
    this.$emit('mounted')
  },
  destroyed() {
    document.removeEventListener('fullscreenchange', this.updateIsFullscreen)
  },
  methods: {
    keydown(key: KeydownEvent) {
      if (key.Escape) {
        this.exitFullscreen()
      } else if (key.F) {
        this.toggleFullscreen()
      }
    },
    toggleFullscreen() {
      if (this.getIsFullscreen()) {
        this.exitFullscreen()
      } else {
        this.requestFullscreen()
      }
    },
    exitFullscreen() {
      if (!this.getIsFullscreen()) return
      if (document.exitFullscreen) {
        document.exitFullscreen().then(this.updateIsFullscreen)
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen().then(this.updateIsFullscreen)
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen().then(this.updateIsFullscreen)
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen().then(this.updateIsFullscreen)
      }
    },
    requestFullscreen() {
      if (this.getIsFullscreen()) return
      if (this.canFullscreen) {
        const el = this.$refs.fullscreenParent as HTMLElement
        if (el.requestFullscreen) {
          el.requestFullscreen().then(this.updateIsFullscreen)
        } else if (el.webkitRequestFullscreen) {
          el.webkitRequestFullscreen().then(this.updateIsFullscreen)
        } else if (el.mozRequestFullScreen) {
          el.mozRequestFullScreen().then(this.updateIsFullscreen)
        } else if (el.msRequestFullscreen) {
          el.msRequestFullscreen().then(this.updateIsFullscreen)
        }
        if (this.focusOnFullscreen) {
          el.focus()
        }
      } else {
        this.$emit('fullscreenFailed')
      }
    },
    updateIsFullscreen() {
      this.isFullscreen = this.getIsFullscreen()
      this.$emit('fullscreen', this.isFullscreen)
    },
    getIsFullscreen(): boolean {
      return !!(
        document.fullscreenElement ||
        document.webkitFullscreenElement ||
        document.mozFullScreenElement ||
        document.msFullscreenElement
      )
    },
  },
})
