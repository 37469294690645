
import Vue from 'vue'
import { PlayerResumeState } from '~/assets/ts/enums'
import { secondsToHhMmSs } from '~/assets/ts/utils/date'
import KeydownEvent from '~/models/generic/KeydownEvent'
import Checkbox from '~/components/_general/Checkbox.vue'
import KeydownObserver from '~/components/_general/KeydownObserver.vue'

export default Vue.extend({
  name: 'PlayerAutoResumeContainer',
  components: { KeydownObserver, Checkbox },
  props: {
    time: {
      type: Number,
      default: 0,
    },
    show: {
      type: Boolean,
    },
    shortcuts: {
      type: Boolean,
    },
  },
  data() {
    return {
      permanent: false,
    }
  },
  computed: {
    displayTime(): string {
      return secondsToHhMmSs(this.time)
    },
  },
  methods: {
    keydown(key: KeydownEvent) {
      if (key.Space || key.Enter) {
        this.setResume(true)
      }
    },
    setResume(resume: boolean) {
      this.$emit('resume', resume)
      if (!this.permanent) return
      this.$store.commit(
        'player/SET_RESUME_STATE',
        resume ? PlayerResumeState.Resume : PlayerResumeState.Restart
      )
    },
  },
})
