export class SubtitleTrack {
  id: number
  label: string
  language: string
  auto: boolean
  constructor(id: number, language: string, label: string, auto: boolean) {
    this.id = id
    this.language = language
    this.label = label
    this.auto = auto
  }

  GetAutoLabel(context: Record<string, any>, label = '') {
    label = label || this.label
    return `${label}${this.auto ? ` (${context.$t('Auto')})` : ''}`
  }
}

export class Subtitle {
  id: string
  track: SubtitleTrack
  text: string
  key: string
  startTime: number
  endTime: number
  constructor(
    id: string,
    track: SubtitleTrack,
    text: string,
    startTime: number,
    endTime: number
  ) {
    this.id = id
    this.track = track
    this.text = text
    this.key = `${id}${track.label}`
    this.startTime = startTime
    this.endTime = endTime
  }
}
