
import Vue, { PropType } from 'vue'
import { Sermon } from '~/models/sermon'
import SaImage from '~/components/_general/SaImage.vue'
import SermonElement from '~/components/_general/SermonElement.vue'
import InlineIcon from '~/components/_general/InlineIcon.vue'
import Poller from '~/components/_general/Poller.vue'

export default Vue.extend({
  name: 'PlayerAutoplayNextContainer',
  components: { Poller, InlineIcon, SermonElement, SaImage },
  props: {
    nextButtonBgColor: {
      type: String,
      required: true,
    },
    autoplaySermon: {
      type: Object as PropType<Sermon>,
      default: undefined,
    },
    started: {
      type: Number,
      required: true,
    },
    delay: {
      type: Number,
      required: true,
    },
    title: {
      type: String,
      default: '',
    },
    thumbnail: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      currentTime: 0,
    }
  },
  computed: {
    timeRemaining(): number {
      return Math.ceil(this.delay - (this.currentTime - this.started) / 1000)
    },
    autoplayNext(): boolean {
      return this.$store.getters['player/autoplayNext']
    },
    showAutoplayNext(): boolean {
      return this.autoplayNext && this.autoplaySermon !== undefined
    },
    thumbnailUrl(): string {
      if (!this.thumbnail || !this.autoplaySermon) return ''
      return this.autoplaySermon.thumbnailURL(320)
    },
  },
  methods: {
    updateCurrentTime() {
      this.currentTime = new Date().getTime()
    },
  },
})
