
import Vue, { PropType } from 'vue'
import { shouldPolyfill } from '@formatjs/intl-displaynames/should-polyfill'
import { SubtitleTrack } from '~/models/player/subtitle'

export default Vue.extend({
  name: 'PlayerSubtitleLabel',
  props: {
    subtitle: {
      type: Object as PropType<SubtitleTrack>,
      required: true,
    },
  },
  data() {
    return {
      localName: this.subtitle.label,
    }
  },
  computed: {
    largeLanguage(): boolean {
      return this.language.length > 2
    },
    language(): string {
      return this.subtitle.language
    },
    sameLanguage(): boolean {
      return this.locale === this.language
    },
    locale(): string {
      return this.$i18n.locale
    },
    displayLabel(): string {
      return this.subtitle.label.split(';')[0]
    },
  },
  async mounted() {
    const unsupportedLocale = shouldPolyfill(this.locale)
    if (unsupportedLocale) {
      await this.importDisplayNameLocale()
    }
    this.setLocalName()
  },
  methods: {
    async importDisplayNameLocale() {
      await import('@formatjs/intl-displaynames/polyfill-force')
      // Full url is required because otherwise ts, eslint, and webpack all throw fits
      await import(
        '../../node_modules/@formatjs/intl-displaynames/locale-data/' +
          this.locale +
          '.js'
      )
    },
    setLocalName() {
      const localName = new (Intl as any).DisplayNames([this.locale], {
        type: 'language',
      }).of(this.language)
      this.localName = this.subtitle.GetAutoLabel(this, localName)
    },
  },
})
