
import Vue from 'vue'
import SiteFeaturedItemLayout from '~/components/site/featured/ItemLayout.vue'

export default Vue.extend({
  name: 'PlayerAudioOnlyContainer',
  components: { SiteFeaturedItemLayout },
  props: {
    prop: {
      type: Boolean,
      default: false,
    },
  },
})
