
import Vue from 'vue'
import { secondsToHhMmSs } from '~/assets/ts/utils/date'
import LiveIndicator from '~/components/_general/LiveIndicator.vue'

export default Vue.extend({
  name: 'PlayerTimeIndicator',
  components: { LiveIndicator },
  props: {
    time: {
      type: Number,
      default: undefined,
    },
    background: {
      type: Boolean,
    },
    live: {
      type: Boolean,
    },
    webcast: {
      type: Boolean,
    },
  },
  computed: {
    timeDisplay(): string {
      if (this.webcast) return this.$t('Live').toString()
      return secondsToHhMmSs(this.time)
    },
  },
  methods: {
    goLive() {
      if (!this.webcast) return
      this.$emit('goLive')
    },
  },
})
