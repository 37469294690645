
import Vue, { PropType } from 'vue'
import { Sermon } from '~/models/sermon'
import { Webcast } from '~/models/webcast'
import {
  siteSermonUrl,
  siteShareUrl,
  siteWebcastUrl,
} from '~/assets/ts/utils/urls'
import { PlayerMediaType } from '~/assets/ts/enums'
import InlineIcon from '~/components/_general/InlineIcon.vue'
import SaIcon from '~/components/_general/SaIcon.vue'
import MediaDownloadDropdown from '~/components/_general/MediaDownloadDropdown.vue'
import ShareButton from '~/components/_general/ShareButton.vue'
import { Broadcaster } from '~/models/broadcaster'

export default Vue.extend({
  name: 'PlayerEmbedDetails',
  components: { ShareButton, MediaDownloadDropdown, SaIcon, InlineIcon },
  props: {
    video: {
      type: Boolean,
    },
    mediaType: {
      type: Number as PropType<PlayerMediaType>,
      default: PlayerMediaType.Audio,
    },
    hasPlayed: {
      type: Boolean,
    },
    showControls: {
      type: Boolean,
    },
    sermon: {
      type: Object as PropType<Sermon>,
      default: undefined,
    },
    webcast: {
      type: Object as PropType<Webcast>,
      default: undefined,
    },
    soloUrls: {
      type: Boolean,
    },
  },
  computed: {
    shareTitle(): string {
      if (this.sermon) {
        return this.sermon.shareTitle
      } else if (this.webcast) {
        return this.webcast.ShareTitle(this)
      }
      return ''
    },
    shareUrl(): string {
      return this.webcast ? this.webcastUrl : this.sermonUrl
    },
    broadcaster(): Broadcaster | undefined {
      return this.webcast ? this.webcast?.broadcaster : this.sermon?.broadcaster
    },
    webcastUrl(): string {
      if (this.soloUrls) {
        return this.webcast?.SoloUrl ?? ''
      }
      return siteWebcastUrl(this.broadcaster)
    },
    sermonUrl(): string {
      if (this.soloUrls) {
        return this.sermon?.SoloUrl ?? ''
      }
      return siteSermonUrl(this.sermon, this.mediaType)
    },
    homeUrl(): string {
      // Use this if we want the SA logo to go to the solo site
      // if (this.soloUrls) {
      //   return this.broadcaster?.SoloUrl ?? ''
      // }
      return siteShareUrl('')
    },
  },
})
