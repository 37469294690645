import { humanFileSize } from '~/assets/ts/utils/math'

export interface QualityConfig {
  id: number
  bitrate?: number
  height?: number
  showBitrate?: boolean
  displayName?: string
}

export class Quality {
  id: number
  bitrate: number
  height: number
  showBitrate: boolean
  audioOnly: boolean
  displayName: string
  constructor(config: QualityConfig) {
    this.id = config.id
    this.height = config.height ?? 0
    this.bitrate = config.bitrate ?? 0
    this.showBitrate = config.showBitrate || false
    this.audioOnly = !!config.height
    this.displayName = config.displayName ?? `${this.height}p`
  }

  get readableBitrate() {
    return humanFileSize(this.bitrate, 1, true)
  }
}
