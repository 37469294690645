
import Vue, { PropType } from 'vue'
import {
  PlayerMediaType,
  SermonMetadataDisplay,
  WebcastDisplay,
} from '~/assets/ts/enums'
import { Sermon } from '~/models/sermon'
import { Webcast } from '~/models/webcast'
import {
  siteSermonUrl,
  siteShareUrl,
  siteWebcastUrl,
} from '~/assets/ts/utils/urls'
import SermonMetadata from '~/components/_general/SermonMetadata.vue'
import WebcastMetadata from '~/components/_general/WebcastMetadata.vue'
import SaLinkOrHref from '~/components/_general/SaLinkOrHref.vue'

export default Vue.extend({
  name: 'PlayerMetadata',
  components: {
    SaLinkOrHref,
    WebcastMetadata,
    SermonMetadata,
  },
  props: {
    video: {
      type: Boolean,
    },
    embed: {
      type: Boolean,
    },
    hasPlayed: {
      type: Boolean,
    },
    showControls: {
      type: Boolean,
    },
    metadataDisplay: {
      type: Number as PropType<SermonMetadataDisplay>,
      default: SermonMetadataDisplay.Minimal,
    },
    sermon: {
      type: Object as PropType<Sermon>,
      default: undefined,
    },
    webcast: {
      type: Object as PropType<Webcast>,
      default: undefined,
    },
    mediaType: {
      type: Number as PropType<PlayerMediaType>,
      default: PlayerMediaType.Audio,
    },
    soloUrls: {
      type: Boolean,
    },
  },
  computed: {
    webcastDisplay(): WebcastDisplay {
      return WebcastDisplay.Embed
    },
    webcastTitle(): string {
      return this.webcast?.Title(this) ?? ''
    },
    webcastUrl(): string {
      if (this.soloUrls) {
        return this.webcast?.SoloUrl ?? ''
      }
      const url = siteWebcastUrl(this.webcast?.broadcaster)
      return this.embed ? siteShareUrl(url) : url
    },
    sermonUrl(): string {
      if (this.soloUrls) {
        return this.sermon?.SoloUrl ?? ''
      }
      const url = siteSermonUrl(this.sermon, this.mediaType)
      return this.embed ? siteShareUrl(url) : url
    },
  },
})
