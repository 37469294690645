
import Vue, { PropType } from 'vue'
import { TranslateResult } from 'vue-i18n'
import { Broadcaster } from '~/models/broadcaster'
import PlayerNotification from '~/components/player/Notification.vue'
import SaIcon from '~/components/_general/SaIcon.vue'
import WebcastPing from '~/components/_general/WebcastPing.vue'

export default Vue.extend({
  name: 'PlayerWebcastNotification',
  components: { WebcastPing, SaIcon, PlayerNotification },
  props: {
    broadcaster: {
      type: Object as PropType<Broadcaster>,
      default: undefined,
    },
    showOffline: {
      type: Boolean,
    },
    showOnline: {
      type: Boolean,
    },
    live: {
      type: Boolean,
    },
  },
  data() {
    return {
      mounted: false,
    }
  },
  computed: {
    browser(): boolean {
      return this.$isClient
    },
    displayText(): TranslateResult {
      if (this.live) return this.$t('Broadcaster is online!')
      return this.$t('Broadcaster is offline')
    },
    show(): boolean {
      if (!this.$isClient) return false
      return (this.showOnline && this.live) || (this.showOffline && !this.live)
    },
  },
  mounted() {
    this.mounted = true
  },
  methods: {
    popoutWebcast() {
      if (!this.live) return
      if (!this.broadcaster) return
      this.broadcaster.openWebcastPopup()
      this.$emit('click')
    },
  },
})
